import type {NextPage} from 'next'
import React, {useEffect, useRef} from "react";
import Layout from "../../components/layouts/Layout";
import Container from "../../components/layouts/Container";
import {routeParam} from "../../utils/routeParam";
import ContentSidebarLeft from "../../components/layouts/ContentSidebarLeft";
import i18next from 'i18next';
import BxFilters from "../../components/boxalino/Filters";
import NavigationBrand from "../../components/navigation/Brand";
import BreadcrumbBrand from "../../components/navigation/breadcrumb/Brand";
import Banner from "../../components/promotion/Banner";
import filterService from "../../services/filterService";
import FilterSelected from "../../components/filter/Selected";
import cookieService from "../../services/cookie/cookieService";
import tagManager from "../../services/tagManager";
import redirectService from "../../services/redirect/redirectService";
import ProductList from "../../components/product/List";
import BrandNavigationOffCanvas from "../../components/brand/navigation/OffCanvas";
import {setQuery} from "../../stores/filter/filterSlice";
import {useDispatch} from "react-redux";
import DailyDealTile from "../../components/daily-deal/DailyDealTile";
import apiInternal from "../../utils/apiInternal";
import getClientIp from "../../utils/getClientIp";
import contextRequestUrl from "../../utils/contextRequestUrl";
import contentParser from "../../services/content/contentParser";
import ContentPage from "../../components/content/Page";

const BrandListingPage: NextPage = (context: any) => {
    const brand = context.brand;
    const dispatch = useDispatch();
    const listingRef = useRef<HTMLHeadingElement>(null);

    const contentsTop = contentParser.parse(context.contentsTop);
    const contentsBottom = contentParser.parse(context.contentsBottom);

    useEffect(() => {
        let d = {
            category: 'Listing',
            countryCode: `${i18next.language}_CH`,
            languageCode: i18next.language,
            pageTitle: `${brand.name} | PerfectHair.ch`,
        }
        tagManager.pageTags(d, 'category', [brand.name]);

        let q = filterService.getQuery(context.filterGroups.filters);
        dispatch(setQuery(q))
    }, []);

    function dailyDeal() {
        if (!context.dailyDeal) return <></>

        return (

            <div className={"ps-3 pe-3 ps-md-0 pe-md-0"}>
                <div className="border no-hover rounded-5 mt-2 p-1 container mt-5">
                    <div className="bg-white rounded-5 pt-3 pb-4">
                        <h2 className="mb-3 ms-2 text-center fs-4">{context.dailyDeal.title}</h2>
                        <hr className={"text-black ms-2 me-2"}/>

                        <DailyDealTile
                            product={context.dailyDeal}
                            productTile={false}
                            className={"w-100 ms-auto row me-auto rounded-4 p-3"}
                            showDescription={true}
                            style={{maxWidth: '800px'}}
                            showWide={true}
                            showCart={true}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Layout context={context} noFollow={brand.nofollow} noIndex={brand.noindex}>
                {(contentsTop) ? (
                    <>{contentsTop.map((content: any, index: number) => (
                        <ContentPage key={`content-page-${brand.id}-${index}`} contents={content}/>
                    ))}</>
                ) : (
                    <>
                        <Banner image={brand.banner} className={"mt-4"} imageAlt={`${i18next.t('brand.imgAltBanner').toString()} ${brand.name}`}/>
                    </>
                )}

                {dailyDeal()}

                <Container>
                    <BreadcrumbBrand key={`breadcrumb-${brand.id}`} brand={brand}/>

                    <ContentSidebarLeft hide={!context.navigation.children || !context.navigation.children.length}
                                        sidebar={(
                        <>
                            {context.navigation && (
                                <NavigationBrand
                                    key={`nav-brand-${brand.id}`}
                                    brand={brand}
                                    pathIds={context.pathIds}
                                    categories={context.navigation.children}/>
                            )}
                        </>
                    )} content={(
                        <>
                            <div className="mb-4 position-relative">
                                <h1 className="fs-3 d-inline" ref={listingRef}>{brand.name}</h1>
                                <span className="ms-md-3 mt-2 mt-md-0 d-block d-md-inline">
                                ({context.listing.total} {i18next.t('general.products').toString()})
                            </span>
                            </div>

                            <BrandNavigationOffCanvas
                                key={`nav-brand-off-${brand.id}`}
                                brand={brand}
                                pathIds={context.pathIds}
                                categories={context.navigation.children}/>

                            <BxFilters filters={context.filters}
                                       key={`list-${context.brand.id}-${context.filterGroups.optionIds}`}/>
                            <FilterSelected filterGroups={context.filterGroups}
                                            bxFacets={context.filters}
                                            key={`selected-${context.filterGroups.optionIds}`}/>

                            <ProductList key={`${brand.id}-${context.page}-${context.filterGroups.optionIds}`}
                                         listing={context.listing.products}
                                         total={context.listing.total}
                                         disableSidebar={!context.navigation.children || !context.navigation.children.length}
                                         bxAttributes={context.listing.bxAttributes}
                                         listingRef={listingRef}/>
                        </>
                    )}/>
                </Container>

                {(contentsBottom) ? (
                    <>{contentsBottom.map((content: any, index: number) => (
                        <ContentPage key={`content-page-${brand.id}-${index}`} contents={content}/>
                    ))}</>
                ) : <></>}

            </Layout>
        </>
    )
}

export async function getServerSideProps(context: any) {
    context.res.setHeader(
        'Cache-Control',
        'public, s-maxage=10, stale-while-revalidate=60'
    )

    let brandId = routeParam(context.query.brand);
    let page = Number(context.query.page) ?? 1;
    let offset = page ? (page - 1) * 24 : 0;
    const filterGroups = filterService.getGroups(context.query);
    let resListing: any;

    try {
        resListing = await apiInternal().post('/api/v2/pages/brand/listing', {
            brandId,
            page,
            offset,
            filterGroups,
            customerId: cookieService.getCustomerId(context),
            customerGroup: cookieService.getCustomerGroup(context),
            language: context.locale
        }, {
                headers: {
                    cookie: cookieService.stringify(context.req.cookies),
                    referer: context.req.headers.referer ?? '',
                    'user-url': contextRequestUrl(context),
                    'user-agent': context.req.headers['user-agent'] ?? '',
                    'x-forwarded-for': getClientIp(context.req) ?? '',
                    'accept-language': context.req.headers["accept-language"]
                }
        })
    }
    catch(e) {
        console.error('brand listing data fetch error', e)
        return redirectService.redirectTo404();
    }

    return {props: resListing.data}
}

export default BrandListingPage
