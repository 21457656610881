import filterService from "../../../services/filterService";
import {useRouter} from "next/router";
import {useDispatch} from "react-redux";

interface Props {
    label: string;
    options: string;
}

export default function FilterSelectedNewInCategory({ label, options }: Props) {
    const router = useRouter();
    const dispatch = useDispatch();

    async function handleClick() {
        await filterService.removeFilter([options], options, 'nic', router, false, dispatch, router.query);
    }

    return (
        <div role="button"
             className="btn-filter border-1 border rounded-5 ps-3 pe-3 pt-2 pb-2"
             onClick={handleClick}
        >
            <span>{label}</span>
            <i className="fa-regular fa-circle-xmark ms-2">
        </i>
</div>
)
    ;
}
