import {Switch} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useRouter} from "next/router";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../stores/store";
import single from "../../../utils/single";
import filterService from "../../../services/filterService";
import {setQuery} from "../../../stores/filter/filterSlice";
import routeService from "../../../services/route/routeService";
import {routeParam} from "../../../utils/routeParam";

interface Props {
    options: any[];
    isOpen: boolean;
    close(): void;
    shallow: boolean;
    className?: string;
    label: string;
}

export default function AFilterOptionNewInCategory({options, shallow, label, className = ''}: Props) {
    const router = useRouter();
    const query = useSelector((state: State) => state.filterState.query);
    const dispatch = useDispatch();

    function getCategoryId() {
        const segment = router.query.category ?? '';
        if (!segment.length) {
            return 0;
        }
        return routeParam(segment);
    }


    const categoryId = getCategoryId();
    const hitCount = options.find(o => Number(o.value) === categoryId).hitCount;

    async function handleChange() {
        const q = filterService.getQuery(shallow ? query : router.query);
        q.page = '1';
        q.nic = String(categoryId);

        if (shallow) {
            dispatch(setQuery(q));
            return;
        }

        await router.push(
            {
                pathname: routeService.getAsPath(router.asPath),
                query: q
            },
            undefined,
            {shallow: shallow ?? false}
        );
    }

    return (
        <div className={`ps-2 pe-2 ${className}`}>
            <FormControlLabel
                control={<Switch checked={Number(single(query.nic)) === categoryId} onChange={handleChange}/>}
                label={`${label} (${hitCount})`}
            />
        </div>
    );
}
